export function initializeWorldMap() {
    let panzoom;

    function openMapModal(position) {
        // If Position is defined, add blinking
        if (position) {
            document.querySelectorAll('.blinking').forEach(el => el.classList.remove('blinking'));
            document.getElementById(position)?.classList.add("blinking");
        }

        MicroModal.show('modal-1');
        if (!panzoom) {
            const elem = document.getElementById('panzoom-element')
            panzoom = Panzoom(elem, {
                maxScale: 5,
                minScale: 0.4,
                panOnlyWhenZoomed: false,
                contain: 'outside',
            })

            setTimeout(() => {
                panzoom.zoom(0.1, { animate: false });
            });

            const zoomInButton = document.getElementById('zoom-in')
            const zoomOutButton = document.getElementById('zoom-out')
            zoomInButton.addEventListener('click', panzoom.zoomIn)
            zoomOutButton.addEventListener('click', panzoom.zoomOut)
        }
    }

    function deplacement(xy, direction) {
        document.getElementById("joueur_icon").className = "moi_" + direction;

        const playerRect = document.getElementById('joueur').getBoundingClientRect();
        const destinationRect = document.getElementById(xy).getBoundingClientRect();
        const animatedDiv = document.getElementById("carte_yuukan_scroll_contenu");
        const computedStyle = window.getComputedStyle(animatedDiv);
        const topOffset = parseInt(computedStyle.top, 10);
        const leftOffset = parseInt(computedStyle.left, 10);

        animatedDiv.style.top = (topOffset + playerRect.top - destinationRect.top) + 'px';
        animatedDiv.style.left = (leftOffset + playerRect.left - destinationRect.left) + 'px';
        
        setTimeout(() => {document.location.href="index.php?page=moteur_moveyuukan&xy=" + xy;}, 200)
    }

    // Expose these functions to the global scope since they are used in HTML
    window.openMapModal = openMapModal;
    window.deplacement = deplacement;
} 